<template>
  <v-dialog v-model="dialog" class="dialog-wrapper" width="650px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="!editMode" depressed class="" :dark="$getPrimaryButton('dark')"
        :rounded="$getPrimaryButton('rounded')" :outlined="$getPrimaryButton('outlined')"
        :color="$getPrimaryButton('color')" v-bind="attrs" v-on="on">
        <v-icon left>add</v-icon>Añadir Centro
      </v-btn>
    </template>
    <v-container fluid>
      <v-card outlined class="p-3" :elevation="$getCardOptions('elevation')">
        <v-card-title>
          <section-header :first="editMode ? 'Edita la información y pincha en guardar' : 'Completa los campos'" second="para actualizar el Centro de Trabajo" />
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <!-- Add v-text-area for description -->
            <label class="input-field--label"> Nombre: </label>
            <v-text-field v-model="formData.name" placeholder="Nombre"
              :color="$getColor('vuetifyColor1')" outlined
              prepend-inner-icon="drive_file_rename_outline">
            </v-text-field>
            <label class="input-field--label"> Descripción: </label>
            <v-textarea v-model="formData.description" placeholder="Descripción"
              :color="$getColor('vuetifyColor1')" outlined prepend-inner-icon="description">
            </v-textarea>
            <label class="input-field--label"> Seleccionar disponibilidad de cuadrantes y fichajes: </label>
            <v-col>
              <v-row>
                <v-switch v-model="formData.availableSchedule" :label="formData.availableSchedule ? 'Cuadrantes disponibles' : 'Cuadrantes no disponibles'" class="mr-5"></v-switch>
                <v-switch v-model="formData.availableClockEntries" :label="formData.availableClockEntries ? 'Fichajes disponibles' : 'Fichajes no disponibles'" class="ml-5"></v-switch>
              </v-row>
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- Start button for close dialog -->
          <v-btn depressed :outlined="$getPrimaryButton('outlined')" class="px-5"
            :rounded="$getPrimaryButton('rounded')" large @click="dialog = false">
            Cancelar
          </v-btn>
          <v-btn v-if="!editMode" class="white--text px-5" :rounded="$getPrimaryButton('rounded')"
            :outlined="$getPrimaryButton('outlined')" :color="$getPrimaryButton('color')" large
            @click="newRegister" :disabled="$v.formData.$invalid">
            Crear nuevo Centro
          </v-btn>
          <v-btn v-else class="white--text px-5" :rounded="$getPrimaryButton('rounded')"
            :outlined="$getPrimaryButton('outlined')" :color="$getPrimaryButton('color')" large
            @click="editWorkcenter" :disabled="$v.formData.$invalid">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import workcenterService from '@/services/workcenter.service';

import SectionHeader from '@/components/Headers/SectionHeader.vue';

export default {
  name: 'edit-workcenter',
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    mixins: [validationMixin],

    formData: {
      name: null,
      description: null,
      availableSchedule: true,
      availableClockEntries: true,
    },

    workcenter: null,

    dialog: false,

  }),
  components: {
    SectionHeader,
  },

  validations() {
    const valObj = {
      formData: {
        name: { required },
        description: { required },
      },

    };
    return valObj;
  },
  methods: {
    click() {
      this.$emit('click');
    },
    updateWorkCenterData(workcenter) {
      this.workcenter = workcenter;
      this.formData.name = workcenter.text;
      this.formData.description = workcenter.description;
      this.formData.availableSchedule = workcenter.availableSchedule;
      this.formData.availableClockEntries = workcenter.availableClockEntries;
    },
    newRegister() {
      workcenterService.createWorkcenter(this.formData)
        .then(() => {
          this.dialog = false;
          // emit event to parent component
          this.$emit('update');
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editWorkcenter() {
      workcenterService.editWorkcenter(this.workcenter.value, this.formData)
        .then(() => {
          this.dialog = false;
          // emit event to parent component
          this.$emit('update');
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openDialog() {
      this.dialog = true;
    },
  },
};
</script>
