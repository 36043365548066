<template>
  <div class="workers-header-filter">
    <div class="card-filter-container">
      <v-text-field
        v-model="filter.idDocument"
        outlined
        label="Buscar por DNI..."
        :color="$getColor('vuetifyColor1')"
        prepend-inner-icon=""
        hide-details
        dense
      >
      </v-text-field>
      <div class="filter--actions">
        <v-tooltip bottom v-if="this.$store.getters['auth/hasRole'](['ROLE_SUPER_ADMIN','ROLE_ADMIN' ])">
          <template v-slot:activator="{ on, attrs }">
            <CreateWorker
                    v-if="workcenterId"
                    :workcenterId="workcenterId"
                    v-bind="attrs"
                    v-on="on"
                  />
          </template>
          <span>Mostrar más filtros</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :dark="$getPrimaryButton('dark')"
              :rounded="$getPrimaryButton('rounded')"
              :outlined="$getPrimaryButton('outlined')"
              color="success"
              v-bind="attrs"
              v-on="on"
              depressed
              @click="clickExpansionFilter()"
            >
              <v-icon>mdi-filter</v-icon>
              Filtros
            </v-btn>
          </template>
          <span>Mostrar más filtros</span>
        </v-tooltip>
      </div>
    </div>
    <v-expansion-panels
      accordion
      outlined
      v-model="expansionPanel"
      multiple
      background-color="transparent"
    >
      <v-expansion-panel background-color="transparent">
        <v-expansion-panel-content background-color="transparent">
          <div class="card-filter-container collapsable">

            <v-text-field
              v-model="filter.name"
              outlined
              label="Nombre"
              :color="$getColor('vuetifyColor1')"
              hide-details
              prepend-inner-icon="person"
              dense
              full-width
            ></v-text-field>
            <v-text-field
              v-model="filter.lastname1"
              outlined
              label="Apellidos"
              :color="$getColor('vuetifyColor1')"
              hide-details
              prepend-inner-icon="mdi-account-badge"
              dense
              full-width
            ></v-text-field>
            <v-text-field
              v-model="filter.email"
              outlined
              label="Email"
              :color="$getColor('vuetifyColor1')"
              hide-details
              prepend-inner-icon="email"
              dense
              full-width
            ></v-text-field>
            <!-- Add v-date picker with v-menu -->
             <!--
            <v-dialog
              ref="dialog"
              v-model="menu"
              :return-value.sync="filter.date"
              persistent
              width="450px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filter.date"
                  label="Fecha de creación"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :color="$getColor('vuetifyColor1')"
                  outlined
                  hide-details
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filter.date"
                scrollable
                :color="$getColor('vuetifyColor1')"
                locale="es"
                first-day-of-week="1"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  :color="$getColor('vuetifyColor1')"
                  @click="menu = false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  :color="$getColor('vuetifyColor1')"
                  @click="$refs.dialog.save(filter.date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog> -->

          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-divider v-if="filterAvailable"></v-divider>

    <div v-if="filterAvailable" class="filter-chips-container">
      <v-chip
        class="ma-2 borrar-filtros"
        color="rgba(0,0,0,0.56)"
        close
        outlined
        @click="resetFilter()"
        @click:close="resetFilter()"
      >
        Resetear filtros
      </v-chip>
      <v-chip
        v-if="filter.idDocument != null && filter.idDocument != ''"
        class="ma-2"
        color="red accent-1"
        close
        dark
        @click:close="filter.idDocument = null"
      >
        DNI: {{ this.filter.idDocument }}
      </v-chip>
      <v-chip
        v-if="filter.name != null && filter.name != ''"
        class="ma-2"
        color="red accent-2"
        close
        dark
        @click:close="filter.name = null"
      >
        Nombre: {{ this.filter.name }}
      </v-chip>
      <v-chip
        v-if="filter.email != null && filter.email != ''"
        class="ma-2"
        color="red accent-3"
        close
        dark
        @click:close="filter.email = null"
      >
        Email: {{ this.filter.email }}
      </v-chip>
      <v-chip
        v-if="filter.namePartners != null && filter.namePartners != ''"
        class="ma-2"
        color="rgba(0,0,0,0.56)"
        close
        dark
        @click:close="filter.namePartners = null"
      >
        Nombre de la pareja: {{ this.filter.namePartners }}
      </v-chip>
      <v-chip
        v-if="filter.lastname1 != null && filter.lastname1 != ''"
        class="ma-2"
        color="rgba(0,0,0,0.56)"
        close
        dark
        @click:close="filter.lastname1 = null"
      >
        Apellido: {{ this.filter.lastname1 }}
      </v-chip>
      <v-chip
        v-if="filter.date != null && filter.date != ''"
        class="ma-2"
        color="rgba(0,0,0,0.56)"
        close
        dark
        @click:close="filter.date = null"
      >
        Fecha: {{ this.filter.date }}
      </v-chip>
      <v-chip
        v-if="filter.type"
        class="ma-2"
        color="red lighten-2"
        close
        dark
        @click:close="resetType"
      >
        Tipo: {{ $getAppointmentTypeValues(this.filter.type).label }}
      </v-chip>
    </div>
    <v-divider></v-divider>
  </div>
</template>

<script>
import authService from '@/services/auth.service';
import CreateWorker from '@/views/_FATDA/components/CreateWorker.vue';

export default {
  name: 'header-filter',
  props: {
    icon: {
      type: String,
      default: 'people',
    },
  },
  components: {
    CreateWorker,
  },
  data: () => ({
    expansionPanel: [],

    dates: [],
    authService,
    unCollapsed: false,
    total: null,
    filter: {
      name: null,
      idDocument: null,
      namePartners: null,
      email: null,
      type: null,
      date: null,
      lastname1: null,
    },
    menu: false,
    workcenterId: null,
  }),
  computed: {
    filterAvailable() {
      return (
        this.filter.idDocument != null
        || this.filter.name != null
        || this.filter.namePartners != null
        || this.filter.type != null
        || this.filter.lastname1 != null
        || this.filter.date != null
        || this.filter.email != null);
    },

  },
  methods: {
    clickExpansionFilter() {
      if (this.expansionPanel.length > 0) {
        this.expansionPanel = [];
      } else {
        this.expansionPanel = [0];
      }
    },
    goToNewWorker() {
      this.$router.push({ name: 'CreateWorker' });
    },
    updateType(val) {
      this.filter.type = val;
    },
    clickDateStart() {
      this.filterStartDate = !this.filterStartDate;
    },
    clickDateEnd() {
      this.filterEndDate = !this.filterEndDate;
    },
    updateTotalBox(val) {
      this.total = val;
    },
    updateParent() {
      this.$emit('updateParent', this.filter);
    },
    applyFilter() {
      this.$emit('applyFilter');
    },
    resetType() {
      this.filter.type = null;
      if (this.$refs.appointmentTypeSelect) {
        this.$refs.appointmentTypeSelect.resetValue();
      }
    },
    resetFilter() {
      this.filter.name = null;
      this.filter.namePartners = null;
      this.filter.idDocument = null;
      this.filter.email = null;
      this.filter.date = null;
      this.filter.lastname1 = null;
      this.filter.type = null;
      this.resetType();
    },
  },
  created() {
    this.updateParent();
  },
  mounted() {
    if (this.$store.state.projectId) {
      this.workcenterId = this.$store.state.projectId;
    }
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      this.unCollapsed = !isJustShown;
    });
  },
};
</script>

<style lang="scss" scoped>
.card-filter-button {
  padding: 0 10px;
  display: flex;
  background-color: #fff;
  border: none;
}

.card-filter-button:hover {
  background-color: #fff;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #fff;
  border-color: #545b62;
  box-shadow: 0 0 0 0.1rem rgb(130 138 145 / 50%);
}

.card-filter-container {
  display: flex;
  justify-content: space-between;
  gap: calc(var(--base) * 16);
  margin: 0;
  margin-bottom: calc(var(--base) * 2);
  margin-top: 0.5rem;
  &.collapsable {
    display: flex;
    justify-content: space-between;
    gap: calc(var(--base) * 2);
    background: transparent;
    margin-bottom: 0;
  }
}

.filter-chips-container {
  margin-bottom: 1em;
  widows: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.checkboxes-wrapper {
  display: flex;
}
.range {
  padding-top: 1em;
  padding-right: 1em;
}

.v-picker--date {
  position: absolute;
  z-index: 9999;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.filter-prediccion {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  label {
    // line-height: 34px;
    margin: 0;
    margin-right: 1em;
  }
}

.fecha-wrapper {
  .v-input {
    margin-bottom: 1em;
  }
}

.checkbox-container {
  margin-right: 2em;

  .v-label {
    margin-right: 0em;
    margin-bottom: 0.5em;
  }

  .v-input__control {
    margin-right: 0.2em;
  }
}

.filter--actions {
  display: flex;
  justify-content: flex-end;
  gap:calc(var(--base) * 2);
  flex-direction: row;
}

.v-btn:not(.v-btn--round).v-size--default {
  // width: 200px;
}

.v-chip.borrar-filtros {
  border: none !important;
}
</style>
<style lang="scss">
.workers-header-filter {
  &
    .v-input.v-input--hide-details.theme--light.v-text-field.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined {
    max-width: calc(var(--base) * 50);
  }
  & .theme--light.v-expansion-panels .v-expansion-panel {
    background: transparent;
  }
}
</style>
