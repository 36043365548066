<template>
  <div class="dashboard-panels">
    <div class="dashboard-panels--tabs mdtec-card no-padding">
      <v-tabs centered grow background-color="white" color="primary" light persistent>
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#tab-1" @click="isN2View = true"
          v-if="this.availableSchedule">
          <v-icon class="mr-3">mdi-calendar</v-icon>
          <span>Generar N2</span>
        </v-tab>
        <v-tab href="#tab-2" @click="isN2View = false">
          <v-icon class="mr-3">mdi-clock-outline</v-icon>
          <span>Exportar fichajes</span>
        </v-tab>
      </v-tabs>
    </div>
    <div class="dashboard-panels--tabs mdtec-card">

      <div v-show="isN2View" class="container-max-width p-3 pt-5">
        <section-header class="pb-5" first="Introduce los rangos de fechas"
        second="y genera el fichero N2 en formato Excel" />
        <label class="input-field--label"> Rango de fechas para variables "Quincenales": </label>
        <v-dialog ref="dialogEnd" v-model="menu15" :return-value.sync="range15" persistent
          width="450px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="range15String" placeholder="Rango variables Quincenales"
              prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
              :color="$getColor('vuetifyColor1')" outlined hide-details></v-text-field>
          </template>
          <v-date-picker v-model="range15" scrollable range :color="$getColor('vuetifyColor1')"
            locale="es" first-day-of-week="1">
            <v-spacer></v-spacer>
            <v-btn text :color="$getColor('vuetifyColor1')" @click="menu15 = false">
              Cancelar
            </v-btn>
            <v-btn text :color="$getColor('vuetifyColor1')" @click="$refs.dialogEnd.save(range15)">
              Seleccionar
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <v-spacer></v-spacer>
        <!-- Add v-date picker with v-menu for dateEnd-->
        <label class="input-field--label mt-5"> Rango de fechas para variables "Mensuales": </label>
        <v-dialog ref="dialogStart" v-model="menuMonth" :return-value.sync="rangeMonth" persistent
          width="450px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="rangeMonthString" placeholder="Rango variables Mensuales"
              prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
              :color="$getColor('vuetifyColor1')" outlined hide-details></v-text-field>
          </template>
          <v-date-picker v-model="rangeMonth" scrollable range :color="$getColor('vuetifyColor1')"
            locale="es" first-day-of-week="1">
            <v-spacer></v-spacer>
            <v-btn text :color="$getColor('vuetifyColor1')" @click="menuMonth = false">
              Cancelar
            </v-btn>
            <v-btn text :color="$getColor('vuetifyColor1')"
              @click="$refs.dialogStart.save(rangeMonth)">
              Seleccionar
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <!-- Button to generate N2 -->
        <v-btn large class="mt-5 white--text" :rounded="$getPrimaryButton('rounded')"
        :outlined="$getPrimaryButton('outlined')" :color="$getPrimaryButton('color')"
        @click="getItems" :disabled="checkDates" :loading="loading">
        Generar N2
      </v-btn>
      </div>

      <!-- TAB 2 - FICHAJES-->

      <div v-show="!isN2View" class="container-max-width p-3 pt-5">
        <ExportClockEntries></ExportClockEntries>
      </div>

    </div>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import scheduleService from '@/services/schedule.service';
import SectionHeader from '@/components/Headers/SectionHeader.vue';
import ExportClockEntries from '@/views/_FATDA/components/ExportClockEntries.vue';

export default {
  name: 'generate-n2',
  data() {
    return {
      loading: false,
      workcenterId: null,
      workcenterName: null,
      // add dates
      range15: null,
      rangeMonth: null,
      menu15: false,
      menuMonth: false,
      // clockEntries
      isN2View: true,
      availableSchedule: true,
    };
  },
  mounted() {
    if (this.$store.state.projectId === null) {
      this.$router.push({ name: 'SelectWorkCenter' });
    } else {
      this.workcenterId = this.$store.state.projectId;
      this.workcenterName = this.$store.state.project;
      if (!this.$store.state.availableSchedule || this.$store.state.availableSchedule === 'false') {
        this.availableSchedule = false;
        this.isN2View = false;
      }
    }
  },
  computed: {
    checkDates() {
      return this.range15 === null || this.rangeMonth === null;
    },
    range15String() {
      // return in format as Desde: 01/01/2020 Hasta: 15/01/2020
      if (this.range15 !== null && this.range15.length > 1) {
        return `Desde: ${this.range15[0]} hasta: ${this.range15[1]}`;
      }
      return null;
    },
    rangeMonthString() {
      // return in format as Desde: 01/01/2020 Hasta: 15/01/2020
      if (this.rangeMonth !== null && this.rangeMonth.length > 1) {
        return `Desde: ${this.rangeMonth[0]} hasta: ${this.rangeMonth[1]}`;
      }
      return null;
    },
    fileName() {
      return `N2_${this.workcenterName}_${this.range15String}_${this.rangeMonthString}`;
    },
  },
  methods: {
    getItems() {
      this.loading = true;
      scheduleService.exportToExcel(this.rangeMonth[0], this.rangeMonth[1], this.range15[0], this.range15[1], this.workcenterId)
        .then((response) => {
          const newurl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = newurl;
          link.setAttribute('download', `${this.fileName}.xlsx`);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },

  },
  components: {
    SectionHeader,
    ExportClockEntries,
  },
};
</script>
<style scoped>
.container-max-width {
  max-width: 800px;
}
</style>
