import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';

import HomeIndex from '../views/Home/HomeIndex.vue';

import UsersManager from '../views/AdminPanel/UsersManager.vue';
import Activity from '../views/AdminPanel/Activity.vue';
import ActivityPanel from '../views/Panels/ActivityPanel.vue';
import NotAllowed from '../views/Panels/NotAllowed.vue';

import Login from '../views/Login/MiddleLogin.vue';

// FATDA
import ConfigureTime from '../views/_FATDA/ConfigureTime.vue';
import ConfigureTimeAdmin from '../views/_FATDA/ConfigureTimeAdmin.vue';
import AdminCalendar from '../views/_FATDA/AdminCalendar.vue';
// import AdminCalendarCollapsed from '../views/_FATDA/AdminCalendarCollapsed.vue';

import Workers from '../views/_FATDA/Workers.vue';
import WorkerPanel from '../views/_FATDA/WorkerPanel.vue';
import SelectWorkCenter from '../views/_FATDA/SelectWorkCenter.vue';
import WorkCenterInformation from '../views/_FATDA/WorkCenterInformation.vue';
import SapCodes from '../views/_FATDA/Codes.vue';
import GenerateN2 from '../views/_FATDA/GenerateN2.vue';
import Home from '../views/_FATDA/Home.vue';
import ConfirmNewPassword from '../views/_FATDA/ConfirmNewPassword.vue';
import Versions from '../views/_FATDA/Versions.vue';

Vue.use(VueRouter);

const routes = [

  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      auth: false,
      children: [],
      parent: null,
      label: 'Login',
    },
  },

  {
    path: '/inicio',
    name: 'Home',
    component: Home,
    nav_available: true,
    admin_role: false,
    icon: 'mdi-home',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Inicio',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_ENCARGADO'],
    },
  },

  // FATDA

  {
    path: '/carga-de-horas',
    name: 'ConfigureTime',
    component: ConfigureTime,
    nav_available: true,
    admin_role: false,
    icon: 'mdi-timer-plus-outline',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Carga de Horas',
      roles: ['ROLE_TRABAJADOR', 'ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_ENCARGADO'],
      workerException: true,
    },
  },

  {
    path: '/personal/cuadrante/:id',
    name: 'ConfigureTimeAdmin',
    component: ConfigureTimeAdmin,
    nav_available: false,
    admin_role: true,
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Cuadrante de empleado',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_ENCARGADO'],
    },
  },

  {
    path: '/cuadrantes',
    name: 'AdminCalendar',
    component: AdminCalendar,
    nav_available: true,
    admin_role: false,
    icon: 'mdi-calendar',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Cuadrantes y fichajes',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_ENCARGADO'],
    },
  },

  {
    path: '/cuadrantes/panel',
    name: 'AdminCalendarCollapsed',
    component: AdminCalendar,
    nav_available: false,
    admin_role: false,
    icon: 'mdi-calendar',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Panel de Cuadrantes y fichajes',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_ENCARGADO'],
    },
  },

  // END FATDA

  {
    path: '/',
    name: 'HomeIndex',
    component: HomeIndex,
    nav_available: false,
    admin_role: false,
    icon: 'mdi-home',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: '',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_ENCARGADO', 'ROLE_TRABAJADOR'],
    },
  },

  {
    path: '/personal',
    name: 'Workers',
    component: Workers,
    nav_available: true,
    admin_role: false,
    icon: 'mdi-account-group',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Personal',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_ENCARGADO'],
    },
  },
  {
    path: '/generar-n2',
    name: 'GenerateN2',
    component: GenerateN2,
    nav_available: true,
    admin_role: false,
    icon: 'functions',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Generar N2',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    },
  },
  {
    path: '/codigos-sap',
    name: 'SapCodes',
    component: SapCodes,
    nav_available: true,
    admin_role: false,
    icon: 'mdi-text-box-multiple',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Códigos SAP',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    },
  },
  {
    path: '/personal/empleado/:id',
    name: 'WorkerPanel',
    component: WorkerPanel,
    nav_available: false,
    admin_role: true,
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Panel de empleado',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_ENCARGADO'],
    },
  },

  {
    path: '/centros-de-trabajo',
    name: 'SelectWorkCenter',
    component: SelectWorkCenter,
    nav_available: true,
    admin_role: true,
    icon: 'mdi-office-building-marker',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Centros de trabajo',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_ENCARGADO'],
    },
  },

  {
    path: '/centros-de-trabajo/:id',
    name: 'WorkCenterInformation',
    component: WorkCenterInformation,
    nav_available: false,
    admin_role: true,
    icon: 'work',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Información de Centro de trabajo',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_ENCARGADO'],
    },
  },

  //
  // ADMIN MANAGER
  //

  {
    path: '/administracion/usuarios',
    name: 'Users',
    component: UsersManager,
    nav_available: true,
    admin_role: true,
    icon: 'mdi-account-cog',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Usuarios',
      roles: ['ROLE_SUPER_ADMIN'],
    },
  },
  {
    path: '/administracion/usuarios/:id',
    name: 'User Panel',
    component: ActivityPanel,
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Panel de usuario',
      roles: ['ROLE_SUPER_ADMIN'],
    },
  },
  {
    path: '/administracion/actividad',
    name: 'Activity',
    component: Activity,
    nav_available: true,
    admin_role: true,
    icon: 'mdi-history',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Actividad',
      roles: ['ROLE_SUPER_ADMIN'],
    },
  },
  {
    path: '/administracion/versiones',
    name: 'Versions',
    component: Versions,
    nav_available: true,
    admin_role: true,
    icon: 'mdi-dice-multiple',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Versiones',
      roles: ['ROLE_SUPER_ADMIN'],
    },
  },
  {
    path: '/validation',
    name: 'Validation',
    component: ConfirmNewPassword,
    meta: {
      auth: false,
      children: [],
      parent: null,
      label: 'Validación',
    },
  },

  {
    path: '/sin-permisos',
    name: 'Not Allowed',
    component: NotAllowed,
    nav_available: false,
    admin_role: false,
    icon: 'warning',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Sin permisos',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_ENLACE', 'ROLE_RESPONSABLE', 'ROLE_USER'],
    },
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if ((to.name !== 'Login' && to.name !== 'Validation')
    && !store.state.auth.status.loggedIn) next({ name: 'Login' });
  else next();
});

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta && toRoute.meta.label ? toRoute.meta.label : 'Home';
  next();
});

export default router;
