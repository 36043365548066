/* eslint-disable class-methods-use-this */
import axios from 'axios';
import authHeader from '@/services/auth-header';
import moment from 'moment-timezone';
import * as XLSX from 'xlsx';

const API_URL = `${process.env.VUE_APP_API_URL}`;

class ClockEntryService {
  getLastClockEntry(workerId) {
    return axios
      .get(`${API_URL}clock-entry/last-clock-entry/${workerId}`, { headers: authHeader() })
      .then((response) => response.data);
  }

  getClockEntriesInRange(workerId, startDate, endDate) {
    return axios
      .get(`${API_URL}clock-entry/clock-entries-in-range/${workerId}`, { params: { dateFrom: startDate, dateTo: endDate }, headers: authHeader() })
      .then((response) => response.data);
  }

  getWorkersClockEntriesInRange(workCenterId, startDate, endDate) {
    return axios
      .get(`${API_URL}clock-entry/workers-clock-entries-in-range/${workCenterId}`, { params: { dateFrom: startDate, dateTo: endDate }, headers: authHeader() })
      .then((response) => response.data);
  }

  addClockEntry1(workerId, lat, long, err) {
    return axios
      .post(`${API_URL}clock-entry/add-entry/${workerId}`, { params: { latitude: lat, longitude: long, errorLocation: err }, headers: authHeader() })
      .then((response) => response.data);
  }

  addClockEntry0(workerId, lat, long, err) {
    return axios
      .post(`${API_URL}clock-entry/add-entry/${workerId}`, { params: { latitude: lat, longitude: long, errorLocation: err } }, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  addClockEntry(workerId, loc) {
    return axios
      .post(`${API_URL}clock-entry/add-entry/${workerId}`, loc, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  addClockEntryManual(workerId, unixTime, type) {
    return axios
      .post(`${API_URL}clock-entry/add-manual-entry/${workerId}/${unixTime}/${type}`, {}, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  addClockEntryAdmin(workerId, unixTime, type) {
    return axios
      .post(`${API_URL}clock-entry/add-admin-entry/${workerId}/${unixTime}/${type}`, {}, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  unixTimeToDateTime(unixTime) {
    // Multiplica el tiempo UNIX por 1000 para convertirlo de segundos a milisegundos
    const milliseconds = unixTime * 1000;
    // Crea un nuevo objeto Date usando el tiempo en milisegundos
    const dateObject = new Date(milliseconds);
    // Retorna la fecha formateada según tus preferencias
    return dateObject.toISOString(); // Puedes personalizar el formato según lo necesites
  }

  unixTimeToLocaleDateTime(unixTime) {
    // Multiplica el tiempo UNIX por 1000 para convertirlo de segundos a milisegundos
    const milliseconds = unixTime * 1000;
    // Crea un nuevo objeto Date usando el tiempo en milisegundos
    const dateObject = new Date(milliseconds);
    // Retorna la fecha formateada según tus preferencias
    return dateObject.toLocaleString(); // Puedes personalizar el formato según lo necesites
  }

  getStringDate(date) {
    const options = {
      weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
    };
    return new Date(date).toLocaleDateString('es-ES', options);
  }

  setCustomTimeFromHours(horas) {
    // Calcular las horas enteras, minutos y segundos
    const horasEnteras = Math.floor(horas);
    const minutosDecimal = (horas - horasEnteras) * 60;
    const minutosEnteros = Math.floor(minutosDecimal);
    const segundos = Math.round((minutosDecimal - minutosEnteros) * 60);

    // Formatear la salida
    const resultado = `${horasEnteras}h ${minutosEnteros}m ${segundos}s`;
    return resultado;
  }

  trimDate(fechaString) {
    // Extraer la parte de la hora eliminando todo antes de la coma y el espacio vacío
    const horaParte = fechaString.split(',')[1].trim();
    return horaParte;
  }

  getGroupedEntriesWithWorkTime(schedule, worker) {
    const grouped = {};
    const today = moment().tz('Europe/Madrid').format('YYYY-MM-DD'); // Obtener la fecha actual en la zona horaria de Madrid
    schedule.forEach((entry) => {
      const date = moment(entry.time).tz('Europe/Madrid').format('YYYY-MM-DD'); // Convertir la fecha a la zona horaria de Madrid
      if (!grouped[date]) {
        grouped[date] = {
          time: date,
          workTime: 0, // Inicializar el tiempo de trabajo para este día
          entries: [],
          currentDay: date === today, // Inicializar el tiempo de trabajo para este día
          alertDayStartWithClockOut: false,
          alertDayEndWithClockIn: false,
          alertDayWithManualEntries: false,
          alertDayWithAdminEntries: false,
          alertDayWithUnequalEntries: false,
          alertDayWithConsecutivesSameTypes: false,
          stringTime: this.getStringDate(date),
          stringWorkTime: null,
          workerEmail: worker && worker.email ? worker.email : null,
          workerId: worker && worker.id ? worker.id : null,
          workerName: worker && worker.name ? worker.name : null,
          workerLastName: worker && worker.lastName1 ? worker.lastName1 : null,
        };
      }
      grouped[date].entries.push(entry);
    });

    // Calcular el tiempo de trabajo para cada día
    Object.values(grouped).forEach((day) => {
      // Verificar si hay entradas consecutivas del mismo tipo
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < day.entries.length; i++) {
        const currentEntry = day.entries[i];
        if (currentEntry.manualEntry) {
          if (currentEntry.adminEntry) {
            // eslint-disable-next-line no-param-reassign
            day.alertDayWithAdminEntries = true;
          } else {
            // eslint-disable-next-line no-param-reassign
            day.alertDayWithManualEntries = true;
          }
        }
        if (i <= day.entries.length - 2) {
          const nextEntry = day.entries[i + 1];
          if (currentEntry.sourceType === nextEntry.sourceType) {
            // eslint-disable-next-line no-param-reassign
            day.alertDayWithConsecutivesSameTypes = true;
          }
        }
      }
      const clockIns = day.entries.filter((entry) => entry.sourceType === 'CLOCK_IN');
      const clockOuts = day.entries.filter((entry) => entry.sourceType === 'CLOCK_OUT');

      if (clockIns.length !== clockOuts.length) {
        // eslint-disable-next-line no-param-reassign
        day.alertDayWithUnequalEntries = true;
      }

      if (day.entries[0].sourceType === 'CLOCK_OUT') {
        // eslint-disable-next-line no-param-reassign
        day.alertDayStartWithClockOut = true;
      }
      if (day.entries[day.entries.length - 1].sourceType === 'CLOCK_IN') {
        // eslint-disable-next-line no-param-reassign
        day.alertDayEndWithClockIn = true;
      }

      // Si el primer registro es CLOCK_OUT y el último es CLOCK_IN, agregamos registros ficticios
      if (day.entries[0].sourceType === 'CLOCK_OUT') {
        clockIns.unshift({
          unixClockInTime: moment(day.time).tz('Europe/Madrid').startOf('day').unix(),
        });
      }

      // Si el primer registro es CLOCK_OUT y el último es CLOCK_IN, agregamos registros ficticios
      if (day.entries[day.entries.length - 1].sourceType === 'CLOCK_IN') {
        clockOuts.push({
          unixClockInTime: moment(day.time).add(1, 'days').tz('Europe/Madrid').startOf('day')
            .unix(),
        });
      }

      // Calcular el tiempo de trabajo sumando las diferencias de tiempo entre cada entrada y salida
      if (clockIns.length !== clockOuts.length) {
        // eslint-disable-next-line no-param-reassign
        day.workTime = 0;
        // eslint-disable-next-line no-param-reassign
        day.stringWorkTime = 'e';
      } else {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < clockIns.length; i++) {
          const timeDifference = (clockOuts[i].unixClockInTime - clockIns[i].unixClockInTime) / 3600; // Convertir a horas
          // eslint-disable-next-line no-param-reassign
          day.workTime += timeDifference;
          // eslint-disable-next-line no-param-reassign
          day.stringWorkTime = this.setCustomTimeFromHours(day.workTime);
        }
      }
    });

    return Object.values(grouped);
  }

  getGroupedEntriesForAllWorkers(schedulesWorkers) {
    // Agrupar las entradas por trabajador
    const groupedByWorker = {};

    schedulesWorkers.forEach((entry) => {
      const workerId = entry.worker.id;
      if (!groupedByWorker[workerId]) {
        groupedByWorker[workerId] = {
          worker: entry.worker,
          schedule: [],
        };
      }
      groupedByWorker[workerId].schedule.push(entry);
    });

    // Aplicar getGroupedEntriesWithWorkTime a cada grupo
    const result = {};

    Object.values(groupedByWorker).forEach((workerData) => {
      const { worker, schedule } = workerData;
      const groupedEntries = this.getGroupedEntriesWithWorkTime(schedule, worker);
      result[worker.id] = {
        worker,
        groupedEntries,
      };
    });

    return result;
  }

  flattenGroupedEntries(result) {
    const allEntries = [];

    Object.values(result).forEach((workerData) => {
      const { groupedEntries } = workerData;
      groupedEntries.forEach((entry) => {
        allEntries.push(entry);
      });
    });

    return allEntries;
  }

  downloadClockEntriesForAllWorkers(clockEntries) {
    const groupedEntries = this.getGroupedEntriesForAllWorkers(clockEntries);
    const clockEntriesExport = clockEntries.map((item) => {
      const outItem = JSON.parse(JSON.stringify(item));

      // Renombrar campos
      outItem.Nombre = outItem.workerName;
      delete outItem.workerName;
      outItem.Apellidos = outItem.workerLastName;
      delete outItem.workerLastName;
      outItem.TipoFichaje = outItem.type;
      delete outItem.type;
      // outItem.FechaYHora = outItem.time;
      outItem.FechaHora = outItem.timeLocale;
      delete outItem.timeLocale;
      // outItem.FechaYHoraISO = outItem.date;
      outItem.UNIX = outItem.unixClockInTime;
      delete outItem.unixClockInTime;
      outItem.Email = outItem.workerEmail;
      delete outItem.workerEmail;

      if (item.adminEntry) {
        outItem.Alerta = 'Registro de Administrador';
      } else if (item.manualEntry) {
        outItem.Alerta = 'Registro Manual';
      } else {
        outItem.Alerta = null;
      }

      outItem.Latitud = outItem.latitude;
      delete outItem.latitude;
      outItem.Longitud = outItem.longitude;
      delete outItem.longitude;
      outItem.ErrorLocalizacion = outItem.errorLocation;
      delete outItem.errorLocation;

      // Eliminar campos
      // delete outItem.id;
      delete outItem.blocked;
      delete outItem.start;
      delete outItem.end;
      delete outItem.color;
      delete outItem.timed;
      delete outItem.icon;
      delete outItem.workerId;
      // delete outItem.time;
      delete outItem.date;
      delete outItem.sourceType;
      delete outItem.manualEntry;
      delete outItem.adminEntry;
      delete outItem.worker;

      return outItem;
    });

    const groupedEntriesFlatted = this.flattenGroupedEntries(groupedEntries);

    const groupedEntriesExport = groupedEntriesFlatted.map((item) => {
      const outItem = JSON.parse(JSON.stringify(item));

      // Renombrar campos
      outItem.Nombre = outItem.workerName;
      delete outItem.workerName;
      outItem.Apellidos = outItem.workerLastName;
      delete outItem.workerLastName;
      outItem.Fecha = outItem.stringTime;
      delete outItem.stringTime;
      outItem.TotalTrabajado = outItem.stringWorkTime;
      delete outItem.stringWorkTime;

      // Modificar campos
      const Alertas = this.setDayAlerts(
        item.alertDayStartWithClockOut,
        item.alertDayEndWithClockIn,
        item.alertDayWithManualEntries,
        item.alertDayWithAdminEntries,
        item.alertDayWithUnequalEntries,
        item.alertDayWithConsecutivesSameTypes,
      );

      if (Alertas && Alertas.length > 0) {
        outItem.TotalAlertas = Alertas.length;
      } else {
        outItem.Alertas = null;
      }

      // Concatenar las entradas
      if (item.entries && item.entries.length > 0) {
        outItem.Fichajes = item.entries.map((entry) => `${entry.type} (${this.trimDate(entry.timeLocale)})`).join(' -> ');
      } else {
        outItem.Fichajes = null;
      }

      // Concatenar las alertas
      if (Alertas && Alertas.length > 0) {
        outItem.Alertas = Alertas.map((alert) => alert).join(' || ');
      } else {
        outItem.Alertas = null;
      }

      // Renombrar campos
      outItem.FechaISO = outItem.time;
      delete outItem.time;
      outItem.Email = outItem.workerEmail;
      delete outItem.workerEmail;
      outItem.TotalHorasTrabajadas = outItem.workTime;
      delete outItem.workTime;

      // Eliminar campos
      delete outItem.workerId;
      delete outItem.workTime;
      delete outItem.currentDay;
      delete outItem.entries;
      delete outItem.alertDayStartWithClockOut;
      delete outItem.alertDayEndWithClockIn;
      delete outItem.alertDayWithManualEntries;
      delete outItem.alertDayWithAdminEntries;
      delete outItem.alertDayWithUnequalEntries;
      delete outItem.alertDayWithConsecutivesSameTypes;

      return outItem;
    });

    // Crear el libro de Excel
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(clockEntriesExport);
    const ws2 = XLSX.utils.json_to_sheet(groupedEntriesExport);

    XLSX.utils.book_append_sheet(wb, ws2, 'Info diaria');
    XLSX.utils.book_append_sheet(wb, ws, 'Entradas y Salidas');

    // Generar el nombre del archivo
    const date = new Date();
    const dateString = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    const fileName = `data_export_admin_${dateString}.xlsx`;

    // Descargar el archivo
    XLSX.writeFile(wb, fileName);
  }

  downloadClockEntries(clockEntries, worker, admin) {
    const groupedEntries = this.getGroupedEntriesWithWorkTime(clockEntries, worker);
    // Aplanar los objetos anidados antes de exportar
    // Función para aplanar objetos, renombrar campos y excluir campos no deseados
    const clockEntriesExport = clockEntries.map((item) => {
      const outItem = JSON.parse(JSON.stringify(item));

      // Renombrar campos
      outItem.Nombre = outItem.workerName;
      delete outItem.workerName;
      outItem.Apellidos = outItem.workerLastName;
      delete outItem.workerLastName;
      outItem.TipoFichaje = outItem.type;
      delete outItem.type;
      // outItem.FechaYHora = outItem.time;
      outItem.FechaHora = outItem.timeLocale;
      delete outItem.timeLocale;
      // outItem.FechaYHoraISO = outItem.date;
      outItem.UNIX = outItem.unixClockInTime;
      delete outItem.unixClockInTime;
      outItem.Email = outItem.workerEmail;
      delete outItem.workerEmail;

      if (item.adminEntry) {
        outItem.Alerta = 'Registro de Administrador';
      } else if (item.manualEntry) {
        outItem.Alerta = 'Registro Manual';
      } else {
        outItem.Alerta = null;
      }

      if (admin) {
        outItem.Latitud = outItem.latitude;
        outItem.Longitud = outItem.longitude;
        outItem.ErrorLocalizacion = outItem.errorLocation;
      }
      delete outItem.latitude;
      delete outItem.longitude;
      delete outItem.errorLocation;

      // Eliminar campos
      delete outItem.id;
      delete outItem.blocked;
      delete outItem.start;
      delete outItem.end;
      delete outItem.color;
      delete outItem.timed;
      delete outItem.icon;
      delete outItem.workerId;
      delete outItem.time;
      delete outItem.date;
      delete outItem.sourceType;
      delete outItem.manualEntry;
      delete outItem.adminEntry;

      return outItem;
    });

    const groupedEntriesExport = groupedEntries.map((item) => {
      const outItem = JSON.parse(JSON.stringify(item));

      // Renombrar campos

      outItem.Nombre = outItem.workerName;
      delete outItem.workerName;
      outItem.Apellidos = outItem.workerLastName;
      delete outItem.workerLastName;
      outItem.Fecha = outItem.stringTime;
      delete outItem.stringTime;
      outItem.TotalTrabajado = outItem.stringWorkTime;
      delete outItem.stringWorkTime;

      // Modificar campos
      const Alertas = this.setDayAlerts(
        item.alertDayStartWithClockOut,
        item.alertDayEndWithClockIn,
        item.alertDayWithManualEntries,
        item.alertDayWithAdminEntries,
        item.alertDayWithUnequalEntries,
        item.alertDayWithConsecutivesSameTypes,
      );

      if (Alertas && Alertas.length > 0) {
        outItem.TotalAlertas = Alertas.length;
      } else {
        outItem.Alertas = null;
      }

      // Concatenar las entradas
      if (item.entries && item.entries.length > 0) {
        outItem.Fichajes = item.entries.map((entry) => `${entry.type} (${this.trimDate(entry.timeLocale)})`).join(' -> ');
      } else {
        outItem.Fichajes = null;
      }

      // Concatenar las alertas
      if (Alertas && Alertas.length > 0) {
        outItem.Alertas = Alertas.map((alert) => alert).join(' || ');
      } else {
        outItem.Alertas = null;
      }

      // Renombrar campos
      outItem.FechaISO = outItem.time;
      delete outItem.time;
      outItem.Email = outItem.workerEmail;
      delete outItem.workerEmail;
      outItem.TotalHorasTrabajadas = outItem.workTime;
      delete outItem.workTime;

      // Eliminar campos
      delete outItem.workerId;
      delete outItem.workTime;
      delete outItem.currentDay;
      delete outItem.entries;
      delete outItem.alertDayStartWithClockOut;
      delete outItem.alertDayEndWithClockIn;
      delete outItem.alertDayWithManualEntries;
      delete outItem.alertDayWithAdminEntries;
      delete outItem.alertDayWithUnequalEntries;
      delete outItem.alertDayWithConsecutivesSameTypes;

      return outItem;
    });

    // Crear el libro de Excel
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(clockEntriesExport);
    const ws2 = XLSX.utils.json_to_sheet(groupedEntriesExport);

    XLSX.utils.book_append_sheet(wb, ws2, 'Info diaria');
    XLSX.utils.book_append_sheet(wb, ws, 'Entradas y Salidas');

    // Generar el nombre del archivo
    const date = new Date();
    const dateString = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    const fileName = `data_export_${dateString}.xlsx`;

    // Descargar el archivo
    XLSX.writeFile(wb, fileName);
    this.loading = false;
  }

  setDayAlerts(
    alertDayStartWithClockOut,
    alertDayEndWithClockIn,
    alertDayWithManualEntries,
    alertDayWithAdminEntries,
    alertDayWithUnequalEntries,
    alertDayWithConsecutivesSameTypes,
  ) {
    const alerts = [];
    if (alertDayStartWithClockOut || alertDayEndWithClockIn) {
      alerts.push('El día comienza con una salida o termina con una entrada');
    }
    if (alertDayWithAdminEntries) {
      alerts.push('Entrada/salida manual de Administrador');
    }
    if (alertDayWithManualEntries) {
      alerts.push('Entrada/salida manual de Usuario');
    }
    if (alertDayWithUnequalEntries) {
      alerts.push('El número de entradas no coincide con el número de salidas');
    }
    if (alertDayWithConsecutivesSameTypes) {
      alerts.push('Hay entradas o salidas consecutivas');
    }
    return alerts;
  }

  getDateTimeRange(val, format) {
    const dateTimeRange = {};
    if (format === 'MM') {
      // minDate = first day of current month
      const minDateClockEntries = new Date(new Date().getFullYear(), val, 2);
      // maxDate = last day of the month
      const maxDateClockEntries = new Date(new Date().getFullYear(), val + 1, 1);
      // Restar un milisegundo para obtener el último momento del mes actual
      maxDateClockEntries.setMilliseconds(maxDateClockEntries.getMilliseconds() - 1);

      const dateFrom = new Date(minDateClockEntries).toISOString().split('T')[0];
      const dateTo = maxDateClockEntries.toISOString();

      dateTimeRange.dateFrom = dateFrom;
      dateTimeRange.dateTo = dateTo;
    } if (format === '[YYYY-MM-DD,YYYY-MM-DD]') {
      const dateFrom = new Date(val[0]).toISOString().split('T')[0];
      const maxDateClockEntries = new Date(val[1]);
      // Añadir un día y restar un milisegundo
      maxDateClockEntries.setDate(maxDateClockEntries.getDate() + 1);
      maxDateClockEntries.setMilliseconds(maxDateClockEntries.getMilliseconds() - 1);
      // Crear la fecha final en el formato ISO y obtener solo la parte de la fecha
      const dateTo = maxDateClockEntries.toISOString();
      dateTimeRange.dateFrom = dateFrom;
      dateTimeRange.dateTo = dateTo;
    } if (format === 'YYYY-MM') {
      const year = parseInt(val.split('-')[0], 10);
      const month = parseInt(val.split('-')[1], 10);
      const firstDayOfMonth = new Date(year, month - 1, 2);
      const lastDayOfMonth = new Date(year, month, 0);
      // Ajustar las fechas para obtener el primer momento del primer día y el último momento del último día
      firstDayOfMonth.setHours(0, 0, 0, 0);
      lastDayOfMonth.setHours(23, 59, 59, 999);

      const dateFrom = firstDayOfMonth.toISOString().split('T')[0];
      const dateTo = lastDayOfMonth.toISOString();

      dateTimeRange.dateFrom = dateFrom;
      dateTimeRange.dateTo = dateTo;
    }
    return dateTimeRange;
  }

  getLocation(navGeolocation) {
    return new Promise((resolve, reject) => {
      if (navGeolocation) {
        navGeolocation.getCurrentPosition(
          (position) => {
            const pos = this.showPosition(position);
            resolve(pos);
          },
          (error) => {
            const errorText = this.showError(error);
            reject(errorText);
          },
        );
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('La geolocalización no es soportada por este navegador.');
      }
    });
  }

  showPosition(position) {
    const pos = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    };
    return pos;
  }

  showError(error) {
    let errorText = null;
    // eslint-disable-next-line default-case
    switch (error.code) {
      case error.PERMISSION_DENIED:
        errorText = 'El usuario denegó la solicitud de geolocalización.';
        break;
      case error.POSITION_UNAVAILABLE:
        errorText = 'La información de la ubicación no está disponible.';
        break;
      case error.TIMEOUT:
        errorText = 'La solicitud de ubicación ha caducado.';
        break;
      case error.UNKNOWN_ERROR:
        errorText = 'Se ha producido un error desconocido.';
        break;
    }
    return errorText;
  }
}

export default new ClockEntryService();
