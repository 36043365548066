<template>
  <v-app>
    <!-- Show message if environment is not production -->
    <v-alert v-if="showMessage === 'true'" type="error" class="text-center" elevation="1" rounded="0" dismissible>
      <span class="font-weight-bold">¡Atención!</span>
      <span class="pl-2">Estás en un entorno de PRE-PRODUCCIÓN</span>
    </v-alert>
    <!-- End show message if environment is not production -->
    <v-navigation-drawer v-if="menuVisible &&
      this.$router.currentRoute.meta.auth &&
      this.authService.getDecodedJwtToken(this.$store.state.auth.user).rol !=
      'ROLE_TRABAJADOR'
      " v-model="drawer" :mini-variant="mini && this.$vuetify.breakpoint.width >= 1024"
      :permanent="this.$vuetify.breakpoint.width >= 1024"
      :app="this.$vuetify.breakpoint.width >= 1024" :absolute="this.$vuetify.breakpoint.width < 1024"
      :temporary="this.$vuetify.breakpoint.width < 1024">
      <v-list-item class="px-2" @click="mini = !mini">
        <v-list-item-avatar>
          <v-avatar :color="$getColor('vuetifyColor1')" size="30">
            <span class="white--text text-h6">{{ userChar }}</span>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-title>
          <div>
            <span>{{
              this.authService.getDecodedJwtToken(this.$store.state.auth.user)
                .sub
            }}</span>
          </div>
        </v-list-item-title>
        <v-btn v-if="menuVisible &&
            this.authService.getDecodedJwtToken(this.$store.state.auth.user)
              .rol != 'ROLE_TRABAJADOR' &&
            this.$vuetify.breakpoint.width < 1024
            " icon @click.stop="[(mini = !mini), (drawer = !drawer)]">
          <v-icon>{{ drawer && mini ? "mdi-menu-open" : "mdi-menu" }}</v-icon>
        </v-btn>
      </v-list-item>
      <v-list-item class="drawbar-project-selector" :class="mini ? 'mini' : ''">
        <v-list-item-icon v-if="mini" @click.stop="[(mini = !mini), (drawer = !drawer)]">
          <v-icon>mdi-office-building-marker</v-icon></v-list-item-icon>
        <v-list-item-content>
          <WorkcenterSelectorNav />
        </v-list-item-content>
      </v-list-item>
      <v-list nav dense v-if="user.worker">
        <v-list-item v-for="item in userRoutesWithWorkerException" :key="item.title" :to="item.path"
          link :color="$getColor('vuetifyColor1')">
          <v-list-item-icon v-if="item.meta.workerException">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content v-if="item.meta.workerException">
            <v-list-item-title>{{ item.meta.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item v-for="item in userRoutesWithoutWorkerException" :key="item.title"
          :to="item.path" link :color="$getColor('vuetifyColor1')">
          <v-list-item-icon v-if="!item.meta.workerException">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content v-if="!item.meta.workerException">
            <v-list-item-title>{{ item.meta.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list nav dense>
        <div v-if="adminRoutes.length > 0" v-show="!mini" class="subtitle-2 ml-4 mb-2">
          Administración
        </div>
        <v-list-item v-for="item in adminRoutes" :key="item.title" :to="item.path" link
          :color="$getColor('vuetifyColor1')">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.meta.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar v-if="this.$router.currentRoute.meta.auth" :color="$getColor('vuetifyAppBar')" dense
      light elevation="0" app>
      <v-btn v-if="menuVisible &&
        this.authService.getDecodedJwtToken(this.$store.state.auth.user)
          .rol != 'ROLE_TRABAJADOR'
        " icon @click.stop="[(mini = !mini), (drawer = !drawer)]">
        <v-icon v-if="this.$vuetify.breakpoint.width >= 1024">{{
          mini ? "mdi-chevron-right" : "mdi-chevron-left"
        }}</v-icon>
        <v-icon v-if="this.$vuetify.breakpoint.width < 1024">{{
          drawer && mini ? "mdi-menu-open" : "mdi-menu"
        }}</v-icon>
      </v-btn>
      <v-toolbar-title>
        <div class="app-logo">
          <img alt="app-logo" src="@/assets/logo.png" />
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="demo-badge">
        <span class="color6 flex-right">
          {{
            this.authService.getDecodedJwtToken(this.$store.state.auth.user).sub
          }}</span>
      </div>
      <v-btn @click="logout" icon>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
      <v-btn icon x-large>
        <v-avatar :color="$getColor('vuetifyColor1')" size="40">
          <span class="white--text text-h5">{{ userChar }}</span>
        </v-avatar>
      </v-btn>
    </v-app-bar>
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-navigation-drawer v-if="this.$router.currentRoute.meta.children.length > 0 ||
        this.$router.currentRoute.meta.parent
        " v-model="subdrawer" :mini-variant="submini" clipped width="300"
        :color="$getColor('vuetifyNavSecondary')">
        <v-list-item class="px-2">
          <v-list-item-content v-if="!submini">
            <v-list-item-title class="text-h6">
              {{ this.$router.currentRoute.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ parentName }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-btn icon @click.stop="submini = !submini">
            <v-icon>{{
              submini ? "mdi-chevron-right" : "mdi-chevron-left"
            }}</v-icon>
          </v-btn>
        </v-list-item>
        <v-list dense>
          <v-list-item v-for="item in childrenRoutes" :key="item.title" :to="item.path" link
            :color="$getColor('vuetifyColor1')">
            <v-list-item-icon>
              <v-icon small>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <div class="admin-app" v-if="this.$router.currentRoute.meta.auth">
        <!-- Show breadcrumbs if view not equal to Home -->
        <div class="breadcrumbs-container" v-if="this.$router.currentRoute.name !== 'Home' &&
          this.$router.currentRoute.name !== 'ConfigureTime' &&
          this.$router.currentRoute.name !== 'Appointments Panel'
          ">
          <v-breadcrumbs :items="breadcrumbsItems">
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
            <template v-slot:itemt="{ itemt }">
              <v-breadcrumbs-item :to="{ name: itemt.name }" :disabled="itemt.disabled">
                {{ itemt }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </div>

        <!-- If using vue-router -->
        <div class="main-layout">
          <router-view></router-view>
        </div>
      </div>
      <div class="public-app" v-else>
        <div class="main-layout">
          <router-view></router-view>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>

// eslint-disable-next-line no-unused-vars
import WorkcenterSelectorNav from '@/views/_FATDA/components/WorkcenterSelectorNavBlock.vue';
import authService from './services/auth.service';

export default {
  components: {
    WorkcenterSelectorNav,
  },
  data: () => ({
    drawer: false,
    mini: false,
    miniDyn: true,
    username: null,
    menuVisible: true,
    expandSingle: true,
    authService,
    userrole: null,
    hasBreadcrumbs: null,
    hasNavBar: null,
    subdrawer: true,
    submini: true,
    showMessage: process.env.VUE_APP_SHOW_MESSAGE,
  }),
  computed: {
    breadcrumbsItems() {
      // get current route
      const route = this.$route;
      const breadcrumbs = [];
      // get route path
      const { path } = route;
      // get route path segments
      const segments = path.split('/');
      // get route path segments length
      const segmentsLength = segments.length;

      // eslint-disable-next-line no-plusplus
      for (let i = 1; i < segmentsLength - 1; i++) {
        const segment = segments[i];
        const segmentName = segment.charAt(0).toUpperCase() + segment.slice(1);
        const segmentTitle = segmentName.replace(/-/g, ' ');
        const to = `/${segments.slice(0, i + 1).join('/')}`;
        breadcrumbs.push({
          text: segmentTitle,
          disabled: true,
          to,
        });
      }
      breadcrumbs.push({
        text: route.meta.label,
        disabled: true,
        to: '/',
      });
      breadcrumbs.unshift({
        text: 'Inicio',
        disabled: false,
        to: '/',
      });
      return breadcrumbs;
    },
    userChar() {
      if (this.authService.getDecodedJwtToken(this.$store.state.auth.user)) {
        const name = this.authService.getDecodedJwtToken(
          this.$store.state.auth.user,
        ).sub;
        return name.substring(1, 0).toUpperCase();
      }
      return 'A';
    },
    user() {
      if (this.authService.getDecodedJwtToken(this.$store.state.auth.user)) {
        return this.authService.getDecodedJwtToken(this.$store.state.auth.user);
      }
      return null;
    },
    userRoutes() {
      // returb filter by nav_available & admin_role true
      return this.$router.options.routes.filter(
        (i) => i.nav_available === true && i.admin_role === false
          && this.$store.getters['auth/hasRole'](i.meta.roles),
      );
    },
    userRoutesWithoutWorkerException() {
      // filter userRoutes where item.meta.workerException is false
      return this.userRoutes.filter((i) => i.meta.workerException !== true);
    },
    userRoutesWithWorkerException() {
      // filter userRoutes where item.meta.workerException is true
      return this.userRoutes.filter((i) => i.meta.workerException === true);
    },

    adminRoutes() {
      return this.$router.options.routes.filter(
        (i) => i.nav_available === true && i.admin_role === true
          && this.$store.getters['auth/hasRole'](i.meta.roles),
      );
    },
    parentName() {
      const { parent } = this.$route.meta;
      if (parent) {
        return parent;
      }
      return 'Submenu';
    },
    childrenRoutes() {
      // get current route
      const route = this.$route;
      // check if has parent in meta
      if (route.meta.parent) {
        // get parent route
        const parentRoute = this.$router.options.routes.find(
          (i) => i.name === route.meta.parent,
        );
        // get parent childrens
        const { children } = parentRoute.meta;
        // get children routes
        const childrenRoutes = children.map((child) => {
          const croute = this.$router.options.routes.find(
            (i) => i.name === child.name,
          );
          return {
            name: croute.meta.label,
            path: croute.path,
            icon: croute.icon,
          };
        });
        return childrenRoutes;
      }
      if (route.meta.children) {
        const childrenRoutes = route.meta.children.map((child) => {
          const croute = this.$router.options.routes.find(
            (i) => i.name === child.name,
          );
          return {
            name: croute.meta.label,
            path: croute.path,
            icon: croute.icon,
          };
        });
        return childrenRoutes;
      }
      return null;
    },
    currentRoute() {
      return this.$router.currentRoute;
    },
  },
  mounted() {
    // eslint-disable-next-line func-names
    if (this.$vuetify.breakpoint.width >= 1024) {
      this.drawer = true;
      this.mini = false;
    }
    this.viewHasNavBar();
    this.viewHasBreadcrumbs();
    this.setDocumentHeightVariable();
    this.setLayoutHeightVariable();
    window.addEventListener('resize', this.onResize);
  },

  methods: {
    setDocumentHeightVariable() {
      document.documentElement.style.setProperty(
        '--app-height',
        `${window.innerHeight}px`,
      );
    },
    setLayoutHeightVariable() {
      if (this.hasBreadcrumbs && !this.hasNavBar) {
        const layoutHeight = window.innerHeight - document.querySelector('.breadcrumbs-container').offsetHeight;
        document.documentElement.style.setProperty(
          '--layout-height',
          `${layoutHeight}px`,
        );
      } else if (!this.hasBreadcrumbs && this.hasNavBar) {
        const layoutHeight = window.innerHeight - document.querySelector('.v-app-bar').offsetHeight;
        document.documentElement.style.setProperty(
          '--layout-height',
          `${layoutHeight}px`,
        );
      } else if (this.hasBreadcrumbs && this.hasNavBar) {
        const layoutHeight = window.innerHeight - document.querySelector('.breadcrumbs-container').offsetHeight - document.querySelector('.v-app-bar').offsetHeight;
        document.documentElement.style.setProperty(
          '--layout-height',
          `${layoutHeight}px`,
        );
      } else if (!this.hasBreadcrumbs && !this.hasNavBar) {
        const layoutHeight = window.innerHeight;
        document.documentElement.style.setProperty(
          '--layout-height',
          `${layoutHeight}px`,
        );
      }
    },
    viewHasBreadcrumbs() {
      if (document.querySelector('.breadcrumbs-container') !== null) {
        this.hasBreadcrumbs = true;
      } else {
        this.hasBreadcrumbs = false;
      }
    },
    viewHasNavBar() {
      if (document.querySelector('.v-app-bar') !== null) {
        this.hasNavBar = true;
      } else {
        this.hasNavBar = false;
      }
    },
    onResize() {
      this.viewHasNavBar();
      this.viewHasBreadcrumbs();
      this.setDocumentHeightVariable();
      this.setLayoutHeightVariable();
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    logout() {
      this.$store.dispatch('auth/logout');
      this.$store.commit('clearProject');
      this.$router.push('/login');
    },
    checkCollapse() {
      // if projectId is not null and this.$route.name is not WorkCenterInformation
      if (this.$store.state.projectId !== null && this.$route.name !== 'SelectWorkCenter') {
        this.$router.push({ name: 'SelectWorkCenter' });
      }
    },
    goBack() {
      this.$router.go(-1);
    },

  },

  watch: {

    $route() {
      // eslint-disable-next-line func-names
      this.$nextTick(function () {
        this.onResize();
      });
    },

  },
};
</script>
<style lang="scss" scoped>

.app-logo {
  height: 100%;
  width: 100%;
  max-width: 90px;
  overflow: hidden;

  img {
    margin: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.v-application--is-ltr .v-toolbar__content>.v-btn.v-btn--icon:first-child+.v-toolbar__title,
.v-application--is-ltr .v-toolbar__extension>.v-btn.v-btn--icon:first-child+.v-toolbar__title {
  padding-left: calc(var(--base) * 0.5);
}

.main-layout {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: calc(var(--base) * 2);
  height: var(--layout-height);
  overflow-y: scroll;
  width: 100%;
  background-color: var(--layoutBg);

  @media (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
  }

  &>div {
    grid-column: 1 / span 6;
    padding: calc(var(--base) * 3);
    padding-top: 0;

    @media (min-width: 1024px) {
      grid-column: 1 / span 12;
    }
  }
}

.drawbar-project-selector {
  padding-top: calc(var(--base) * 2);
  padding-bottom: calc(var(--base) * 1);

  &.mini {
    padding-top: 0;
    padding-bottom: 0;
  }

  & .dashboard-toolbar--field-group {
    gap: calc(var(--base) * 2);
    display: flex;
    flex-direction: column;
  }
}
</style>
<style>
.v-breadcrumbs.theme--light {
  padding-left: calc(var(--base) * 3);
}

.v-alert {
  position: absolute;
  margin: auto;
  bottom: 0;
  width: 100%;
  z-index: 999;
}
</style>
