<template>
    <div class="dashboard-toolbar--selector">
      <v-progress-circular v-if="loading" :color="$getColor('vuetifyColor1')" class="linear-progress" indeterminate>
      </v-progress-circular>
      <div v-else class="dashboard-toolbar--field-group">
        <div class="workcenter-title">{{ this.workcenter }}</div>
        <!--
        <div class="dashboard-toolbar--field-group__label work-center">
          <v-icon small>mdi-office-building-marker</v-icon>
          <div>{{ this.workcenter }}</div>
        </div>
        -->
        <div class="dashboard-toolbar--field-group__value">
          <div class="dashboard-toolbar--field-group__value__text">
            <!-- vuetify input selector -->
            <v-select :items="workcenters" placeholder="Seleccionar..." outlined dense hide-details
              @change="updateWorkcenter" prepend-inner-icon="mdi-office-building-marker">
              <span class="selected-center">{{
                workcenter
              }}</span></v-select>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import workcenterService from '@/services/workcenter.service';

export default {
  name: 'workcenter-selector-nav',
  props: {
  },
  data: () => ({
    workcenters: null,
    sourceOptions: [],
    selected: null,

    loading: true,
  }),
  created() {
    this.selected = this.workcenterId;
    this.getWorkcenters();
  },
  computed: {
    workcenterId() {
      if (this.$store.state.projectId) {
        // return as int
        return parseInt(this.$store.state.projectId, 10);
      }
      return null;
    },
    workcenter() {
      if (this.$store.state.project) {
        return this.$store.state.project;
      }
      return null;
    },
  },
  watch: {
    workcenterId() {
      this.selected = this.workcenterId;
    },
  },

  methods: {
    updateWorkcenter(newWorkCenter) {
      this.loading = true;
      // find in workcenters array the selected workcenter
      const val = this.workcenters.find((ele) => ele.value === newWorkCenter);
      this.$store.commit('setProject', val);
      this.$router.go();
      this.loading = false;
    },

    getWorkcenters() {
      this.loading = true;
      workcenterService.getAllWorkcenters().then((response) => {
        this.sourceOptions = response.content.map((ele) => ({
          value: ele.id,
          text: ele.name,
          description: ele.description,
          typeId: ele.workcenterType,
          creationTime: new Date(ele.creationTime).toLocaleDateString(),
          icon: 'business_center',
          color: '#424242',
          availableClockEntries: ele.availableClockEntries,
          availableSchedule: ele.availableSchedule,
        }));
        this.workcenters = this.sourceOptions;
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.workcenter-title{
    line-height: 1.25;
    font-weight: 500;
    //color: #262626;
    padding-bottom: 0.5rem;
    color: #363636;
    max-width: 225px;

}
.dashboard-toolbar--field-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &__label {
      font-size: calc(var(--base) * 2);
      line-height: calc(var(--base) * 2);
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: calc(var(--base) * 0.2);
      &.work-center {
        padding-bottom: calc(var(--base) * 1.1);
      }
    }

    &__value {
      font-weight: 500;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      align-items: flex-end;

      &.text-red {
        color: #f4796b;
      }
      &.text-green {
        color: #00bfa5;
      }
      &__text {
        display: block;
      }
      span.selected-center {
        font-size: calc(var(--base) * 2.5);
        line-height: auto;
        font-weight: 600;
        margin-left: calc(var(--base) * 0.3);
      }
      span.number {
        font-size: calc(var(--base) * 3.5);
        margin-right: calc(var(--base) * 0.5);
        line-height: calc(var(--base) * 2.2);
      }
      span.unit {
        font-size: calc(var(--base) * 2);
      }
    }
  }</style>
