<template>
  <div class="view--select-work-center">
    <CreateWorkcenter @update="getWorkcenters" class="create-work-center mr-3"
      ref="createWorkcenter" v-if="this.isAdmin" />
    <EditWorkCenter @update="getWorkcenters" :editMode="true" class="create-work-center"
      ref="editWorkcenter" v-if="this.isAdmin" />
    <!-- Ir a fichajes-->
    <v-btn v-if="this.user && this.user.worker" :dark="$getPrimaryButton('dark')" depressed
      class="ml-3" :rounded="$getPrimaryButton('rounded')" :outlined="false"
      :color="$getPrimaryButton('color')" right @click="goToCargaHoras">
      <v-icon left>mdi-calendar</v-icon>Ir a carga de horas
    </v-btn>
    <!-- loading -->
    <HeaderFilter @updateParent="updateFilter" @tableView="setTableView" icon="people" ref="headerFilter" />
    <v-progress-linear class="mt-5" v-if="loading" :color="$getColor('vuetifyColor1')"
      :indeterminate="loading">
    </v-progress-linear>
    <div v-else>
      <div v-show="tableView">
        <v-expand-transition>
        <v-data-table :headers="headers" :items="filteredOptions" :items-per-page="10"
          class="elevation-1" calculate-widths :color="$getColor('vuetifyColor1')" outlined flat
          elevation="1" full-width :footer-props="{
        showFirstLastPage: true,
        'items-per-page-text': 'Elementos por página',

      }">
          <template v-slot:[`item.avatar`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon class="mr-1" :dark="$getPrimaryButton('dark')" depressed
                  :rounded="$getPrimaryButton('rounded')" :outlined="$getPrimaryButton('outlined')"
                  :color="$getPrimaryButton('color')"
                  @click="updateWorkcenter(item, 'AdminCalendar')" v-bind="attrs" v-on="on">
                  <v-icon>mdi-calendar</v-icon>
                </v-btn>
              </template>
              <span>Ir a Cuadrantes</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.text`]="{ item }">
            <span class="font-weight-medium">{{ item.text }}</span>
          </template>
          <template v-slot:[`item.totalWorkers`]="{ item }">
            <v-avatar color="success" size="35"><span class="white--text">{{ item.totalWorkers
                }}</span></v-avatar>
          </template>
          <template v-slot:[`item.config`]="{ item }" >
            <v-icon medium v-if="item.availableSchedule" :color="$getColor('vuetifyColor2')">mdi-calendar-check</v-icon>
            <v-icon medium v-if="item.availableClockEntries" :color="$getColor('vuetifyColor2')">mdi-clock-check</v-icon>
          </template>

          <template v-slot:[`item.totalIncidences`]="{ item }">
            <v-avatar v-if="item.totalIncidences > 0" color="primary" size="35"><span
                class="white--text">{{ item.totalIncidences }}</span></v-avatar>
            <v-avatar v-else color="grey" size="35"><span
                class="white--text">{{ item.totalIncidences }}</span></v-avatar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-col>
              <v-row>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small class="mr-4" :dark="$getPrimaryButton('dark')" depressed
                      :rounded="$getPrimaryButton('rounded')"
                      :outlined="$getPrimaryButton('outlined')" :color="$getPrimaryButton('color')"
                      @click="updateWorkcenter(item, 'Home')" v-bind="attrs" v-on="on">
                      <v-icon>mdi-home</v-icon>
                    </v-btn>
                  </template>
                  <span>Ir al panel de inicio del centro de trabajo</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small icon :dark="$getPrimaryButton('dark')"
                      :rounded="$getPrimaryButton('rounded')"
                      :outlined="$getPrimaryButton('outlined')" :color="$getPrimaryButton('color')"
                      @click="editWorkcenter(item)" v-bind="attrs" v-on="on" depressed>
                      <v-icon>mdi-square-edit-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar centro de trabajo</span>
                </v-tooltip>
              </v-row>
            </v-col>
          </template>
        </v-data-table>
      </v-expand-transition>
      </div>
      <div class="select-work-center--grid" v-show="!tableView">

        <mdtec-panel v-for="option in filteredOptions" :key="option.value"
          class="work-center--item">
          <template v-slot:title>
            <!--
          <v-icon>mdi-office-building</v-icon>
          -->
            <span class="work-center--item__title">{{ option.text }}
            </span>

          </template>
          <template v-slot:content>
            <div class="work-center--item__description">
              "{{ option.description }}"
            </div>
            <div class="work-center--item--insights">
              <div class="work-center--item--insights__group">
                <div class="work-center--item--insights__group__icon">
                  <v-icon>mdi-account-group</v-icon>
                </div>
                <div class="work-center--item--insights__group__value">
                  {{ option.totalWorkers }}
                  Empleados/as
                </div>
              </div>
              <div class="work-center--item--insights__group">
                <div class="work-center--item--insights__group__icon">
                  <v-icon color="red accent-1">mdi-alert-circle</v-icon>
                </div>
                <div class="
                  work-center--item--insights__group__value
                  red--text
                  text--accent-1
                ">
                  {{ option.totalIncidences }}
                </div>
              </div>
              <!-- wc availability -->
              <div>
                <v-col>
                  <v-row>
                    <div class="work-center--item--insights__group" v-if="option.availableSchedule">
                      <div class="work-center--item--insights__group__icon">
                        <v-icon medium color="success">mdi-calendar-check</v-icon>
                      </div>
                    </div>
                    <div class="work-center--item--insights__group"
                      v-if="option.availableClockEntries">
                      <div class="work-center--item--insights__group__icon">
                        <v-icon medium color="success">mdi-clock-check</v-icon>
                      </div>
                    </div>
                  </v-row>
                </v-col>
              </div>
            </div>
            <div class="work-center--item__edition">
              <v-btn depressed class="mr-auto" :dark="$getPrimaryButton('dark')" small
                :rounded="$getPrimaryButton('rounded')" :outlined="true" color="grey"
                @click="editWorkcenter(option)">
                <v-icon left>mdi-square-edit-outline</v-icon>Editar
              </v-btn>
            </div>
          </template>
          <template v-slot:footerActions>
            <v-row justify="space-around">
              <v-btn outlined :color="$getColor('vuetifyColor1')"
                @click="updateWorkcenter(option, 'Home')">Seleccionar</v-btn>
              <v-btn dark :color="$getColor('vuetifyColor1')"
                @click="updateWorkcenter(option, 'AdminCalendar')">
                <v-icon left>mdi-calendar</v-icon>Cuadrantes
              </v-btn>
            </v-row>
          </template>
        </mdtec-panel>
        <mdtec-panel class="work-center--add-center" isInteractive @click.native="addWorkCenter()"
          v-if="this.$store.getters['auth/hasRole'](['ROLE_SUPER_ADMIN'])">
          <template v-slot:content>

            <div class="work-center--add-center__icon">
              <v-icon color="primary" size="64">mdi-plus</v-icon>
            </div>
            <div class="work-center--add-center__text">
              <span class="red--text text--accent-1">
                Añadir centro de trabajo
              </span>
            </div>
          </template>
        </mdtec-panel>
      </div>

    </div>
  </div>
</template>

<script>
import workcenterService from '@/services/workcenter.service';
import scheduleService from '@/services/schedule.service';
import CreateWorkcenter from '@/views/_FATDA/components/CreateWorkCenter.vue';
import EditWorkCenter from '@/views/_FATDA/components/EditWorkCenter.vue';
import MdtecPanel from '@/components/Cards/MdtecPanel.vue';
import authService from '@/services/auth.service';
import HeaderFilter from '@/components/Filter/WorkcenterHeaderFilter.vue';

export default {
  name: 'select-workcenter',
  components: {
    CreateWorkcenter,
    MdtecPanel,
    HeaderFilter,
    EditWorkCenter,
  },
  data: () => ({
    project: null,
    options: [],
    sourceOptions: [],
    info: null,
    loading: true,
    errored: false,
    userId: null,
    userRole: null,
    userName: null,
    user: null,
    admins: [],

    filter: null,

    isAdmin: false,
    tableView: false,

    headers: [
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'avatar',
      },
      {
        text: 'Nombre',
        align: 'start',
        sortable: true,
        value: 'text',
      },
      { text: '', value: 'config', width: '90px' },
      { text: 'Descripción', value: 'description' },
      { text: 'Incidencias', value: 'totalIncidences' },
      { text: 'Trabajadores', value: 'totalWorkers' },
      {
        text: 'Acciones', value: 'actions', sortable: false, width: '110px',
      },
    ],
  }),

  created() {
    this.isAdmin = this.$store.getters['auth/hasRole'](['ROLE_SUPER_ADMIN']);
  },

  mounted() {
    this.user = authService.getDecodedJwtToken(this.$store.state.auth.user);

    this.getWorkcenters();
  },

  computed: {
    filteredOptions() {
      if (this.options.length <= 0 || !this.filter.name) {
        return this.options;
      }
      return this.options.filter((option) => option.text.toLowerCase().includes(this.filter.name.toLowerCase()));
    },
  },

  methods: {
    updateFilter(val) {
      this.filter = val;
      this.loading = false;
    },
    setTableView(val) {
      this.tableView = val;
    },
    updateWorkcenter(val, route) {
      this.project = val;
      this.$store.commit('setProject', val);
      this.$router.push({ name: route });
    },
    editWorkcenter(workcenter) {
      this.$refs.editWorkcenter.openDialog();
      this.$refs.editWorkcenter.updateWorkCenterData(workcenter);
    },
    getWorkcenters() {
      this.loading = true;
      workcenterService.getAllWorkcenters().then((response) => {
        this.sourceOptions = response.content.map((ele) => ({
          value: ele.id,
          text: ele.name,
          description: ele.description,
          typeId: ele.workcenterType,
          creationTime: new Date(ele.creationTime).toLocaleDateString(),
          icon: 'business_center',
          color: '#424242',
          totalWorkers: 0,
          totalIncidences: 0,
          availableClockEntries: ele.availableClockEntries,
          availableSchedule: ele.availableSchedule,
        }));
        this.options = this.sourceOptions;
        this.getWorkcentersWorkers();
        this.getWorkcentersIncidences();
      });
    },
    getWorkcentersWorkers() {
      // for each workcenter, get the workers
      this.options.forEach((workcenter) => {
        const params = {
          workCenter: workcenter.value,
        };
        workcenterService
          .getWorkers(params)
          .then((response) => {
            // eslint-disable-next-line no-param-reassign
            workcenter.totalWorkers = response.totalElements;
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    getWorkcentersIncidences() {
      this.options.forEach((workcenter) => {
        scheduleService
          .countUnsolvedIncidencesByWorkCenter(workcenter.value)
          .then((response) => {
            // eslint-disable-next-line no-param-reassign
            workcenter.totalIncidences = response;
          });
      });
    },
    addWorkCenter() {
      this.$refs.createWorkcenter.openDialog();
    },
    goToCargaHoras() {
      this.$router.push({ name: 'ConfigureTime' });
    },
  },
};
</script>
<style lang="scss" scoped>
.view--select-work-center {
  .select-work-center--grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: calc(var(--base) * 2);
    margin-top: calc(var(--base) * 2);

    @media (min-width: 1024px) {
      justify-content: flex-start;
    }

    .work-center--item {
      width: 100%;
      min-height: calc(100vw - var(--base) * 4);
      max-height: calc(100vw - var(--base) * 4);

      @media (max-width: 1024px) {
        min-width: 300px;
        min-height: auto;
      }

      @media (min-width: 1024px) {
        min-width: 300px;
        min-height: 300px;
        max-width: 340px;
        max-height: 340px;
      }

      .work-center--item__description {
        font-size: calc(var(--base) * 1.45);
        margin: calc(var(--base) * 1) 0;
        font-style: italic;
      }

      .work-center--item__edition {
        font-size: calc(var(--base) * 1.45);
        margin: calc(var(--base) * 0.1) 0;
      }

      .work-center--item__title {
        font-size: calc(var(--base) * 2.25);
        margin-bottom: calc(var(--base) * 1);
      }

      .work-center--item--admins {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        margin-top: auto;

        &__label {
          font-size: calc(var(--base) * 2.25);
          margin-bottom: calc(var(--base) * 1);
        }

        &__list {
          display: flex;
          flex-wrap: wrap;
          gap: calc(var(--base) * 0.5);
        }
      }

      .work-center--item--insights {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: calc(var(--base) * 1);

        .work-center--item--insights__group {
          display: flex;
          align-items: center;
          gap: calc(var(--base) * 0.5);

          &__icon {
            font-size: calc(var(--base) * 2.25);
          }

          &__value {
            font-size: calc(var(--base) * 2.25);
          }
        }
      }
    }

    .work-center--add-center {
      width: calc(100vw - var(--base) * 4);
      min-height: calc(100vw - var(--base) * 4);
      max-height: calc(100vw - var(--base) * 4);

      @media (min-width: 1024px) {
        min-width: 300px;
        min-height: 300px;
        max-width: 340px;
        max-height: 340px;
      }

      &:hover {
        background: rgba(var(--bodyCorporate), 0.1);

        .work-center--add-center__icon {
          transform: scale(1.2);
        }
      }

      .mdtec-card--content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .work-center--add-center__icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          transition: all ease-out 0.3s;
        }

        .work-center--add-center__text {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          span {
            text-align: center;
            font-size: calc(var(--base) * 1.75);
            max-width: 80%;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 1.25px;
          }
        }
      }
    }
  }

  .avatar-text {
    font-size: calc(var(--base) * 1.75);
    text-transform: uppercase;
    text-align: center;
  }
}
</style>
<style lang="scss">
.view--select-work-center {
  & .work-center--add-center {
    & .mdtec-card--content {
      height: 100% !important;
      justify-content: space-between;
    }
  }

  & .work-center--item {
    &.mdtec-card {
      justify-content: space-between;

      @media (max-width: 1024px) {
        padding: calc(var(--base) * 2);
      }
    }

    & .mdtec-card--content {
      height: 100% !important;
      gap: calc(var(--base) * 2);
    }

    & .mdtec-card--footer {
      &__actions {
        width: 100%;
      }

      height: auto !important;
      align-items: center;
    }
  }
}
</style>
