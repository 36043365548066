<template>
  <div>
    <mdtec-panel>
      <template v-slot:content>
        <!-- Start list of dates with the details of the event -->
        <div class="header-clock-list">
          <span class="mr-auto text--secondary text-decoration-underline">
            Lista de fichajes:
          </span>
          <v-btn @click="allView = !allView" icon :dark="$getPrimaryButton('dark')" depressed
            :rounded="false" :outlined="false" :color="$getPrimaryButton('color')" class="ml-auto">
            <v-icon>{{ !allView ? 'mdi-arrow-expand' : 'mdi-format-list-bulleted' }}</v-icon>
          </v-btn>
          <InfoAlertsClockEntries></InfoAlertsClockEntries>
        </div>
        <v-list v-if="allView">
          <v-list-item-group>
            <v-list-item v-for="item in entries" :key="item.id" selectable>
              <v-list-item-content>
                <v-list-item-title class="text-subtitle-2">
                  <p class="line-height-1 text-subtitle-1 m-0 ">{{ item.time }}</p>
                  <p class="line-height-1 m-0 mb-3"><span
                      class="pb-3 font-weight-light text-caption">{{ item.stringTime }}</span></p>
                  <div>
                    <v-chip outlined color="#f4796b" text-color="black" label
                      class="font-weight-medium mr-3" v-if="item.workTime">
                      {{ item.currentDay && item.alertDayEndWithClockIn ? 'En Proceso...' : item.stringWorkTime }}
                    </v-chip>
                    <v-tooltip bottom v-if="item.alertDayWithAdminEntries">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="#bd45d1" v-bind="attrs" v-on="on">mdi-security</v-icon>
                      </template>
                      <span>Entrada/Salida manual de Administrador</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="item.alertDayStartWithClockOut || item.alertDayEndWithClockIn && !item.currentDay">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on"
                      >mdi-alert-circle</v-icon>
                      </template>
                      <span>El día comienza con una Salida o termina con una Entrada</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="item.alertDayWithManualEntries">
                      <template v-slot:activator="{ on, attrs }">
                      <v-icon color="success" v-bind="attrs" v-on="on"
                      >mdi-alert-decagram</v-icon>
                      </template>
                      <span>Entrada/Salida manual de Usuario</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="item.alertDayWithUnequalEntries && !item.currentDay">
                      <template v-slot:activator="{ on, attrs }">
                      <v-icon color="orange" v-bind="attrs" v-on="on"
                      >mdi-alert-rhombus</v-icon>
                      </template>
                      <span>El número de entradas no coincide con el número de salidas</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="item.alertDayWithConsecutivesSameTypes">
                      <template v-slot:activator="{ on, attrs }">
                      <v-icon color="red" v-bind="attrs" v-on="on"
                      >mdi-alert-box</v-icon>
                      </template>
                      <span>Hay entradas o salidas consecutivas</span>
                    </v-tooltip>
                  </div>
                </v-list-item-title>
                <!-- for each code in item.codes, show the code.code and code.name -->
                <v-row class="mt-3 m-auto">
                  <div v-for="entry in item.entries" :key="entry.id">
                    <v-chip
                      :color="entry.adminEntry ? '#bd45d1' : entry.manualEntry ? 'success' : null"
                      text-color="black" class="font-weight-bold p-2 mb-1 mr-1" small
                      :outlined="entry.manualEntry ? false : true">
                      {{ entry.type }} <span class="text-caption ml-1">{{ trimDate(entry.timeLocale)
                        }}</span>
                        <v-icon small right v-if="isAdmin && checkGeoData(entry)">mdi-map-marker</v-icon>
                    </v-chip>
                  </div>
                </v-row>
                <v-list-item-subtitle>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <div v-if="!allView">
          <v-expansion-panels v-model="panel" multiple focusable light>
            <v-expansion-panel v-for="(item, i) in entries" :key="i">
              <v-expansion-panel-header disable-icon-rotate>
                <div class="text-subtitle-2">
                  <p class="line-height-1 text-subtitle-1 m-0 ">{{ item.time }}</p>
                  <p class="line-height-1 m-0"><span class="pb-3 font-weight-light text-caption">{{ item.stringTime }}</span></p>
                </div>
                <template v-slot:actions>
                  <v-tooltip bottom v-if="item.alertDayWithAdminEntries">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="#bd45d1" v-bind="attrs" v-on="on">mdi-security</v-icon>
                      </template>
                      <span>Entrada/Salida manual de Administrador</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="item.alertDayStartWithClockOut || item.alertDayEndWithClockIn && !item.currentDay">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on"
                      >mdi-alert-circle</v-icon>
                      </template>
                      <span>El día comienza con una Salida o termina con una Entrada</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="item.alertDayWithManualEntries">
                      <template v-slot:activator="{ on, attrs }">
                      <v-icon color="success" v-bind="attrs" v-on="on"
                      >mdi-alert-decagram</v-icon>
                      </template>
                      <span>Entrada/Salida manual de Usuario</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="item.alertDayWithUnequalEntries && !item.currentDay">
                      <template v-slot:activator="{ on, attrs }">
                      <v-icon color="orange" v-bind="attrs" v-on="on"
                      >mdi-alert-rhombus</v-icon>
                      </template>
                      <span>El número de entradas no coincide con el número de salidas</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="item.alertDayWithConsecutivesSameTypes">
                      <template v-slot:activator="{ on, attrs }">
                      <v-icon color="red" v-bind="attrs" v-on="on"
                      >mdi-alert-box</v-icon>
                      </template>
                      <span>Hay entradas o salidas consecutivas</span>
                    </v-tooltip>

                  <v-chip color="#f4796b" outlined text-color="black" label class="font-weight-medium ml-3"
                    v-if="item.workTime && !item.alertDayWithConsecutivesSameTypes">
                    {{ item.currentDay && item.alertDayEndWithClockIn ? 'En Proceso...' : item.stringWorkTime }}
                  </v-chip>
                  <v-chip color="red" text-color="white" label class="font-weight-regular ml-3"
                    v-else>
                    ERROR
                  </v-chip>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="mt-3 m-auto px-5">
                  <div v-for="entry in item.entries" :key="entry.id">
                    <v-chip
                      :color="entry.adminEntry ? '#bd45d1' : entry.manualEntry ? 'success' : null"
                      text-color="black" class="font-weight-bold p-2 mb-1 mr-1" small
                      :outlined="entry.manualEntry ? false : true" @click="goToGoogleMaps(entry)">
                      {{ entry.type }} <span class="text-caption ml-1">{{ trimDate(entry.timeLocale)
                        }}</span>
                        <v-icon small right v-if="isAdmin && checkGeoData(entry)">mdi-map-marker</v-icon>
                    </v-chip>
                  </div>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </template>
    </mdtec-panel>
  </div>
</template>

<script>
import authService from '@/services/auth.service';
import clockEntryService from '@/services/clockentry.service';
import MdtecPanel from '@/components/Cards/MdtecPanel.vue';
import InfoAlertsClockEntries from '@/views/_FATDA/components/InfoAlertsClockEntries.vue';

export default {
  props: {
  },
  components: {
    MdtecPanel,
    InfoAlertsClockEntries,
  },
  data() {
    return {
      authService,
      // new data
      isAdmin: false,
      schedule: [],
      loading: false,
      panel: [],
      allView: false,
      worker: null,
    };
  },
  computed: {
    entries() {
      // Obtén las entradas agrupadas con tiempo de trabajo usando scheduleService
      const groupedEntries = clockEntryService.getGroupedEntriesWithWorkTime(this.schedule, this.worker);

      // Retorna las entradas ordenadas por 'time'
      return groupedEntries;
    },

  },

  methods: {
    all() {
      this.panel = [...Array(this.entries).keys()].map((k, i) => i);
    },
    // Reset the panel
    none() {
      this.panel = [];
    },

    updateSchedule(val, worker) {
      this.loading = true;
      this.worker = worker;
      this.schedule = val;
      this.loading = false;
    },

    trimDate(fechaString) {
      // Extraer la parte de la hora eliminando todo antes de la coma y el espacio vacío
      const horaParte = fechaString.split(',')[1].trim();
      return horaParte;
    },

    // function to go back to calendar
    backToCalendar() {
      this.$emit('backToCalendar');
    },

    // function to check if entry has geolocation data
    checkGeoData(entry) {
      if (entry.latitude && entry.longitude) {
        return true;
      }
      return false;
    },
    goToGoogleMaps(entry) {
      if (this.checkGeoData(entry)) {
        // console.log('goToGoogleMaps', entry.latitude, entry.longitude);
        const url = `https://www.google.com/maps?q=${entry.latitude},${entry.longitude}`;
        // const url = `https://www.google.com/maps/@${entry.latitude},${entry.longitude}`;
        // window.location.href = url;
        window.open(url, '_blank');
      }
    },
  },
  created() {
    this.isAdmin = this.$store.getters['auth/hasRole'](['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']);
  },
};
</script>
<style lang="scss" scoped>
.header-clock-list {
  display: inline-flex;
  align-items: center;
  padding: 0 1rem;
}

.blocked--day {
  font-size: 25px;
  padding-left: 5px;
}

.view--worker-panel {
  height: 100%;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .grid-container {
    display: grid;
    gap: calc(var(--base) * 2);
    grid-template-columns: repeat(12, 1fr);
    height: 100%;

    .left-side {
      grid-column: 1 / span 5;
    }

    .right-side {
      grid-column: 6 / span 7;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .card {
      padding: 0;
      padding-top: 2em;
    }

    .full-height {
      height: 100%;
    }
  }
}

.button-edit {
  margin-right: 1em;
  margin-left: 1em;
  display: flex;
  justify-content: flex-end;
}

.worker-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  padding: calc(var(--base) * 3) 0;
}

.worker-info {
  padding-top: calc(var(--base) * 2);
}

.dashboard_box {
  border: solid 1px #e5e5e5;
}

.card-thumbnail {
  padding: 1em;
  width: 100%;
  // min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;

  .card-avatar {
    margin: 0;
  }
}

.card-label.v-card__text {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0.5em;
}

.v-card__title.card-title {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 1em;
}

// .projects-table {
//   padding-left: 1em;
//   padding-right: 1em;
// }

.card-divider {
  margin-bottom: 2em;
}

.card-actions.v-card__actions {
  padding: 1em;
  margin-top: 0;
}

.dashboard_box.chart-box {
  margin-bottom: 1em;
}

.panel-tab {
  margin-bottom: 1em;
}

.chart-box {
  padding: 1em;
}

.card-role-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 1em;
  padding-right: 1em;

  .role-title {
    font-size: 1.25rem;
  }
}

.add-project-dialog {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.edit-user-dialog {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 1em;
}

.rol-select {
  margin-right: 1em;
}
</style>
<style lang="scss">
.line-height-1 {
  line-height: 1 !important;
}

.view--worker-panel {
  & .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgba(var(--bodyColor), 0.1) !important;
    color: rgba(var(--bodyColor), 0.6) !important;
  }

  & .theme--dark.v-btn.v-btn--disabled,
  .theme--dark.v-btn.v-btn--disabled .v-btn__loading,
  .theme--dark.v-btn.v-btn--disabled .v-icon {
    color: rgba(var(--bodyColor), 0.6) !important;
  }

  & .mdtec-card.worker-panel {
    height: 100%;
  }

  & .mdtec-card.calendar {
    height: 100%;

    & .mdtec-card--content {
      height: 100%;

      & .admin--worker-calendar {
        height: 100%;

        & .calendar {
          height: 100%;
          display: flex;
          flex-direction: column;
        }
      }
    }

    & .theme--light.v-calendar-weekly .v-calendar-weekly__day {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    & .v-event.v-event-start.v-event-end.white--text {
      width: 98% !important;

      & .event-chip {
        color: #161616 !important;
        padding: 0 calc(var(--base) * 0.5);
        font-weight: 400;
        justify-content: flex-start;
      }
    }
  }
}
</style>
