/* eslint-disable class-methods-use-this */
import axios from 'axios';
import authHeader from '@/services/auth-header';

const API_URL = `${process.env.VUE_APP_API_URL}`;

class ScheduleService {
  getDaysInRange(workerId, startDate, endDate) {
    return axios
      .get(`${API_URL}schedule/days-in-range/${workerId}`, { params: { dateFrom: startDate, dateTo: endDate }, headers: authHeader() })
      .then((response) => response.data);
  }

  addDayToWorkerSchedule(workerId, scheduleDay) {
    return axios
      .post(`${API_URL}schedule/add-day/${workerId}`, scheduleDay, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  updateDay(dayId, scheduleDay) {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel();
    }
    this.cancelTokenSource = axios.CancelToken.source();
    return axios
      .patch(`${API_URL}schedule/update-day/${dayId}`, scheduleDay, {
        headers: authHeader(),
        cancelToken: this.cancelTokenSource.token,
      })
      .then((response) => response)
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log('Request canceled');
        } else {
          console.log(error);
        }
      });
  }

  changeDayBlockedStatus(dayId) {
    return axios
      .patch(`${API_URL}schedule/change-blocked-status/${dayId}`, {}, {
        headers: authHeader(),
      })
      .then((response) => response);
  }

  exportToExcel(dateFromMonthlyValue, dateToMonthlyValue, dateFrom15Value, dateTo15Value, workCenterIdValue) {
    return axios
      .get(`${API_URL}schedule/export-to-excel`, {
        params: {
          dateFromMonthly: dateFromMonthlyValue,
          dateToMonthly: dateToMonthlyValue,
          dateFrom15: dateFrom15Value,
          dateTo15: dateTo15Value,
          workCenterId: workCenterIdValue,
        },
        headers: authHeader(),
        responseType: 'blob',
      })
      .then((response) => response);
  }

  // INCIDENCES
  getIncidencesByWorkCenter(workCenterId) {
    return axios
      .get(`${API_URL}schedule/all-incidences-by-work-center/${workCenterId}`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  getIncidencesByWorkCenterPaged(workCenterId, params) {
    return axios
      .get(`${API_URL}schedule/all-incidences-by-work-center/${workCenterId}`, { params, headers: authHeader() })
      .then((response) => response.data);
  }

  countUnsolvedIncidencesByWorkCenter(workCenterId) {
    return axios
      .get(`${API_URL}schedule/count-unsolved-incidences-by-work-center/${workCenterId}`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  countSolvedIncidencesByWorkCenter(workCenterId) {
    return axios
      .get(`${API_URL}schedule/count-solved-incidences-by-work-center/${workCenterId}`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  getIncidencesByWorker(workerId) {
    return axios
      .get(`${API_URL}schedule/all-incidences-by-worker/${workerId}`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  solveIncidence(incidenceId) {
    return axios
      .patch(`${API_URL}schedule/solve-incidence/${incidenceId}`, {}, {
        headers: authHeader(),
      })
      .then((response) => response);
  }

  addManagerComment(dayId, text) {
    // post as plain text
    // 'Content-Type': 'text/plain'
    const headers = authHeader();
    headers['Content-Type'] = 'text/plain';

    return axios
      .post(`${API_URL}schedule/add-manager-comment/${dayId}`, text, {
        headers,
      })
      .then((response) => response);
  }
}

export default new ScheduleService();
