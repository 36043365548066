<template>
  <v-dialog v-model="dialog" class="dialog-wrapper" width="650px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn :dark="$getPrimaryButton('dark')" depressed :rounded="$getPrimaryButton('rounded')"
        :outlined="false" :color="$getPrimaryButton('color')" v-bind="attrs" v-on="on" large
        @click="openDialog">
        <v-icon left>mdi-clock</v-icon>Fichar
      </v-btn>
    </template>
    <v-container fluid>
      <v-card outlined class="p-3" :elevation="$getCardOptions('elevation')">
        <v-card-title class="p-0">
          <v-spacer></v-spacer>
          <v-btn class="ml-auto" :rounded="$getPrimaryButton('rounded')"
                :color="$getPrimaryButton('color2')" @click="closeDialog" :disabled="false"
                :loading="loading" icon large>
                <v-icon large>mdi-window-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-title class="pt-0">
          <section-header :first="ComputedTexts.first" :second="ComputedTexts.second" />
        </v-card-title>
        <v-card-text v-if="!loading">
          <v-form ref="form">
            <!-- Add time-picker for info
            <v-time-picker format="24hr" readonly width="200"></v-time-picker>
           -->
            <div class="box-info">
              <v-icon style="margin: auto;" size="125" v-if="lastClockInTimeType === 'CLOCK_OUT'  || lastClockInTimeType === null"
                :color="$getPrimaryButton('color')">mdi-clock-plus</v-icon>
                <v-icon style="margin: auto;" size="125" v-if="lastClockInTimeType === 'CLOCK_IN'"
                color="success">mdi-progress-clock</v-icon>
            </div>
            <div class="box-info">
              <div class="input-clock--label" v-if="lastClockInTimeType === 'CLOCK_OUT' || lastClockInTimeType === null" > {{ ComputedDateTimeNow }} </div>
              <div class="input-clock--label" style="color: #00bfa5"  v-if="lastClockInTimeType === 'CLOCK_IN'"> {{ ComputedDifferenceTime }}
              </div>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions class="mt-5">
          <v-spacer></v-spacer>
          <v-container>
            <v-row>
              <v-spacer></v-spacer>
              <v-btn v-if="!loading" class="pr-2 white--text" :rounded="$getPrimaryButton('rounded')"
                :outlined="$getPrimaryButton('outlined')" :color="lastClockInTimeType === 'CLOCK_IN' ? $getPrimaryButton('color') : 'success' "
                @click="addClockEntry" :disabled="false" :loading="loading" width="48%" large block>
                <v-icon class="pr-3" left>{{ lastClockInTimeType === "CLOCK_IN" ? "mdi-stop" : "mdi-play"
                  }}</v-icon>
                {{ lastClockInTimeType === "CLOCK_IN" ? "Parar" : "Iniciar" }}
              </v-btn>
              <!--
                <v-btn class="ml-auto" :rounded="$getPrimaryButton('rounded')"
                :color="$getPrimaryButton('color2')" @click="closeDialog" :disabled="false"
                :loading="loading" width="48%" large>
                <v-icon class="pr-3" left>mdi-window-close</v-icon>
                Atrás
              </v-btn>
               -->

            </v-row>
          </v-container>

        </v-card-actions>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import clockEntryService from '@/services/clockentry.service';

import SectionHeader from '@/components/Headers/SectionHeader.vue';
import moment from 'moment-timezone';

export default {
  name: 'clock-entry-register',
  data: () => ({

    dialog: false,
    loading: false,
    currentDateTime: null,
    lastClockInTimeType: null,
    lastClockInTimeDate: null,
    DateTimeNow: new Date(),

    location: {
      latitude: null,
      longitude: null,
      errorLocation: null,
    },

  }),
  props: {
    workerId: {
      required: true,
    },
    worker: {
      required: false,
    },
    isAdmin: {
      type: Boolean,
      required: true,
      default: false,
    },
    currentValue: {
      required: false,
    },
  },
  components: {
    SectionHeader,
  },
  computed: {
    ComputedDateTimeNow() {
      return this.DateTimeNow.toLocaleString();
    },

    ComputedDifferenceTime() {
      const diferencia = this.DateTimeNow - this.lastClockInTimeDate;

      const horas = Math.floor(diferencia / (1000 * 60 * 60));
      const minutos = Math.floor((diferencia % (1000 * 60 * 60)) / (1000 * 60));
      const segundos = Math.floor((diferencia % (1000 * 60)) / 1000);
      return `${horas} h, ${minutos} min y ${segundos} seg`;
    },
    ComputedTexts() {
      if (this.loading) {
        return { first: '', second: 'Cargando...' };
      }
      if (this.lastClockInTimeType && this.lastClockInTimeType === 'CLOCK_IN') {
        return { first: 'Pincha en parar', second: 'para fichar la salida...' };
      }
      return { first: 'Pincha en iniciar', second: 'para fichar la entrada...' };
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.updateCurrentDateTime();
        this.fetchLocation();
      }
    },
  },
  mounted() {
    this.getLastClockEntry();
    this.fetchLocation();
    // Actualizar fecha y hora cada segundo
    setInterval(() => {
      this.DateTimeNow = new Date();
    }, 1000);
  },
  methods: {
    async fetchLocation() {
      try {
        const location = await clockEntryService.getLocation(navigator.geolocation);
        this.location.latitude = location.latitude;
        this.location.longitude = location.longitude;
        this.location.errorLocation = null;
      } catch (error) {
        this.location.latitude = null;
        this.location.longitude = null;
        this.location.errorLocation = error;
      }
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    updateCurrentDateTime() {
      const now = new Date();
      // Formatear la fecha y hora según tus necesidades
      const formattedDateTime = this.formatDateTime(now);
      this.currentDateTime = formattedDateTime;
    },
    formatDateTime(dateTime) {
      const options = {
        year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric',
      };
      return new Intl.DateTimeFormat('es-ES', options).format(dateTime);
    },
    getLastClockEntry() {
      this.loading = true;
      clockEntryService.getLastClockEntry(this.worker.id).then((response) => {
        if (response) {
          this.lastClockInTimeType = response.type;
          // this.lastClockInTimeDate = new Date(response.clockInTime);
          // Convertir el tiempo Unix a la fecha y hora de Madrid
          const lastClockInTimeUnixFormated = moment.unix(response.unixClockInTime).tz('Europe/Madrid');
          // Formatear la fecha y hora en el formato deseado
          this.lastClockInTimeDate = new Date(lastClockInTimeUnixFormated);
        }
        this.loading = false;
      });
    },
    addClockEntry() {
      this.loading = true;
      clockEntryService.addClockEntry(this.worker.id, this.location)
        .then(() => {
          this.getLastClockEntry();
          this.refreshClockEntries();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
        });
    },
    refreshClockEntries() {
      this.$emit('refreshClockEntries');
    },
  },
};
</script>
<style scoped>
.input-clock--label{
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: calc(var(--base) * 3);
  margin-left: auto;
  margin-right: auto;
  color: #f4796b;
  font-weight: 300;
}
.box-info{
  display: flex;
  margin: auto;
}
</style>
